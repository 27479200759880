<script>
import Base from '@backend/Base.vue';
import BOGen from '@helper/BOGen';
export default {
    name: "BoMaps",
    extends: Base,
    data() {
        return {
            Name: "BoMaps",
            catColor: [],
        }
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
    },
    methods:{
        point_it(event){
            var pos_x = event.offsetX?(event.offsetX):event.pageX-document.getElementById("pointer_div").offsetLeft;
            var pos_y = event.offsetY?(event.offsetY):event.pageY-document.getElementById("pointer_div").offsetTop;
            var x_width =  $('#pointer_div').width();
            var y_height = $('#pointer_div').height();
            let coord_x = ((pos_x-2)/x_width)*100;
            let coord_y = ((pos_y-10)/y_height)*100;
            $('#cross').css("top",parseInt(coord_y-5)+'%');
            $('#cross').css("left",parseInt(coord_x-1)+'%');
            this.row.mm_top = parseInt(coord_y);
            this.row.mm_left = parseInt(coord_x);
        }
    },
    watch: {
        '$route.query'(v) {
            this.refreshData()
        },
    }
};
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>

    <!-- ========================================================================
    LIST DATA
    ============================================================================= -->
    <div class="panel panel-flat" v-if="!$route.params.id">
        <div class="panel-body panel-filter">
            <VForm @resp="search">
            <div class="row">
                <div class="col-sm-3">
                    <h3 class="box-title">{{ObjectName}} List <span
                    v-if="data.total">({{(data.total||0).format()}})</span></h3>
                </div>
                <div class="col-sm-5"></div>
                <div class="col-sm-3">
                    <div class="has-feedback has-feedback-left">
                        <input type="search" v-model="filter.search" class="form-control" placeholder="Type and hit Enter">
                        <div class="form-control-feedback">
                            <i class="icon-search4 text-size-base text-muted"></i>
                        </div>
                    </div>
                </div>
                <div class="col-sm-1">
                    <router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
                </div>
            </div>
            </VForm>
        </div>
        <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>
                            <SortField name="mm_title"></SortField>
                        </th>
                        <th>{{fields.mm_prod_type}}</th>
                        <th>{{fields.mm_photo}}</th>
                        <th>{{fields.mm_is_active}}</th>
                        <th width="120px">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(v,k) in data.data" :key="k">
                        <td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
                        <td>{{v.mm_title}}</td>
                        <td>{{v.mm_prod_type}}</td>
                        <td><ImagePreview :src="uploader(v.mm_photo)"></ImagePreview></td>
                        <td>
                            <StatusLabel :status="v.status"></StatusLabel>
                        </td>
                        <td class="btn-action">
                            <router-link class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'">
                            <i class="icon-pencil"></i></router-link>
                            <a href="javascript:;" class="icon_action" @click="changeStatus(k)"
                            v-tooltip="'Change Status'"><i class="icon-settings"></i></a>
                            <a href="javascript:;" class="icon_action" @click="deleteItem($event,k)"
                            v-tooltip="'Remove'"><i class="icon-trash"></i></a>
                        </td>
                    </tr>
                    <tr v-if="NotFound">
                        <td colspan="99">
                            <h3 class="tc">{{NotFound}}</h3>
                        </td>
                    </tr>
                    <tr v-if="data.data===false">
                        <td colspan="99">
                            <LoadingSpinner light></LoadingSpinner>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="panel-body">
            <div class="pull-right">
                <Pagination :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
            </div>
        </div>
    </div>

    <!-- ========================================================================
    FORM CRUD
    ============================================================================= -->
    <div class="panel panel-flat" v-if="$route.params.id">
        <VForm @resp="submitForm" method="post">
            <div class="panel-heading">
                <h2 class="panel-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h2>
            </div>
            <div class="panel-body">
                <div class="info"></div>
                <div class="row">
                    <div class="col-sm-6">
                        <BoField name="mm_title" v-model="row.mm_title" v-bind="validation('mm_title')"></BoField>
                        <BoField name="mm_phone" v-model="row.mm_phone" v-bind="validation('mm_phone')"></BoField>
                        <BoField name="mm_desc">
                            <textarea rows="4" class="form-control" name="mm_desc" v-model="row.mm_desc" v-bind="validation('mm_desc')"></textarea>
                        </BoField>
                        <BoField name="mm_photo" info="Ukuran: 320x213px">
                            <Uploader name="mm_photo" type="maps" uploadType="galery" v-model="row.mm_photo" v-bind="validation('mm_photo')"></Uploader>
                        </BoField>
                        <BoField name="mm_link_google" v-model="row.mm_link_google" v-bind="validation('mm_link_google')"></BoField>
                    </div>
                    <div class="col-sm-6">
                        <BoField name="mm_company_name" v-model="row.mm_company_name" v-bind="validation('mm_company_name')"></BoField>
                        <BoField name="mm_prod_type" v-model="row.mm_prod_type" v-bind="validation('mm_prod_type')"></BoField>
                        <BoField name="mm_email" v-model="row.mm_email" v-bind="validation('mm_email')"></BoField>
                        <BoField name="mm_address">
                            <textarea rows="4" class="form-control" name="mm_address" v-model="row.mm_address" v-bind="validation('mm_address')"></textarea>
                        </BoField>
                        <BoField name="mm_background">
                            <input type="color" class="form-control" name="mm_background" v-model="row.mm_background">
                        </BoField>
                        <BoField name="mm_is_active">
                            <div class="radio-group">
                                <radio name="mm_is_active" v-model="row.mm_is_active" option="Y"
                                :attr="validation('mm_is_active')">Active</radio>
                                <radio name="mm_is_active" v-model="row.mm_is_active" option="N">Inactive</radio>
                            </div>
                        </BoField>
                    </div>
                    <div class="col-sm-12">
                        <div class="form-group">
                            <!-- <label class="col-sm-2 control-label"> Point Maps <span class="asterisk">*</span></label> -->
                            <div class="col-sm-10">
                                <p class="help-block">Pilih lokasi dan klik untuk mengisi produksi di area implementasi.</p>
                                <div id="pointer_div" @click="point_it" :style="'background-image: url(\''+assets('fo_images','indo_map.png')+'\');height: 300px;background-repeat:no-repeat;background-size:contain;background-position:center;border: 1px solid #ccc;margin-bottom: 13px;padding-top:10px;'">
                                    <img :src="assets('fo_images','pin-maps.png')" id="cross" style="position:relative;z-index:2;top:50%;left:50%;">
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-1 control-label"> Point X</label>
                                    <div class="col-sm-3">
                                    
                                    <input readonly type="text" class="form-control" v-model="row.mm_top" name="mm_top" value="50">
                                    </div>
                                    <label class="col-sm-1 control-label"> Point Y</label>
                                    <div class="col-sm-3">
                                        <input readonly type="text" class="form-control" v-model="row.mm_left" name="mm_left" value="50">
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="clearfix"></div>
                    <div class="hr-top">
                        <div class="col-sm-6">
                            <BoField name="mm_seo_title" v-model="row.mm_seo_title" v-bind="validation('mm_seo_title')"></BoField>
                            <BoField name="mm_seo_keyword">
                                <TagsInput name="mm_seo_keyword" suggestUrl="/select-tags-article" v-model="row.mm_seo_keyword"
                                    :attr="validation('mm_seo_keyword')"></TagsInput>
                            </BoField>
                        </div>
                        <div class="col-sm-6">                        
                            <BoField name="mm_seo_desc">
                                <textarea rows="5" class="form-control" name="mm_seo_desc" v-model="row.mm_seo_desc" v-bind="validation('mm_seo_desc')"></textarea>
                            </BoField>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <div class="text-right">
                            <button type="submit" class="fcbtn btn btn-info btn-outline btn-1e btn-loading">Submit form <i class="icon-arrow-right14 position-right"></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </VForm>
    </div>
</div>
</template>